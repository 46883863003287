<template>
  <div @click="hideAllDropdown">
    <BreadCrumb
      title="Legal Advice"
      subtitle="Legal Argument / Result / Summary"
    >
    </BreadCrumb>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center left" @click="goBack">
        <span class="material-symbols-rounded me-2">arrow_back</span>
        <span class="mb-0">Back</span>
      </div>
      <div
        class="d-flex align-items-center right"
        id="export"
        v-if="activeSection === 'caseSummary' || activeSection === 'precedents'"
      >
        <span class="mb-0">Export</span>
        <span class="material-symbols-rounded me-2"> keyboard_arrow_up </span>
      </div>
    </div>
    <b-tooltip
      target="export"
      triggers="click"
      placement="bottomleft"
      id="export_box"
      :custom-class="`DROPDOWN-MENU`"
    >
      <div style="padding: 0px 10px">
        <template v-if="activeSection === 'caseSummary'">
          <div
            v-for="(item, index) in dropdownItems"
            :key="index"
            class="dropdown-option"
            style="display: flex; align-items: center; margin-bottom: 8px"
          >
            <!-- Replace the standard checkbox with the CheckBox component -->
            <CheckBox
              :set="selectedExportOptions.includes(item)"
              @input="toggleExport($event, item)"
            />
            <label :for="`option-${index}`" style="margin-left: 8px">{{
              item
            }}</label>
          </div>
        </template>
        <div
          style="display: flex; align-items: center"
          v-else
          class="ms-1 me-1"
          @click="exportToExcel"
        >
          <img class="me-2" src="@/assets/icon/excel-icon.svg" />
          <span class="dropdown-text">Export as Excel</span>
        </div>
        <div
          class="d-flex align-items-center justify-content-end"
          v-if="activeSection === 'caseSummary'"
        >
          <div class="d-flex right align-items-center" @click="exportData">
            <span class="material-symbols-rounded me-2"> ios_share </span>
            <span class="mb-0">Export</span>
          </div>
        </div>
      </div>
    </b-tooltip>

    <div class="row">
      <div class="col-md-2 mt-5">
        <ul class="left-list">
          <li
            @click="activeSection = 'caseSummary'"
            :class="{ active: activeSection === 'caseSummary' }"
          >
            Case Summary
          </li>
          <li
            @click="activeSection = 'completeCase'"
            :class="{ active: activeSection === 'completeCase' }"
          >
            Complete Case
          </li>
          <li
            @click="activeSection = 'precedents'"
            :class="{ active: activeSection === 'precedents' }"
          >
            Precedents
          </li>
        </ul>
      </div>
      <div class="col-md-8">
        <!-- first -->
        <div
          v-if="activeSection === 'caseSummary'"
          class="main-container d-flex mt-4"
        >
          <section class="case-summary-content-full-view" id="case-summary">
            <header class="case-summary-header">
              <div class="case-flow-result">{{ this.case_name }}</div>
            </header>

            <article class="case-info mt-3">
              <p class="header">Case Info</p>
              <section class="card topic-card">
                <h5 class="topic-title">Topic</h5>
                <p class="topic-description">
                  {{ basic_details[0]?.topic }}
                </p>
              </section>
              <section class="card abstract-card">
                <h5 class="abstract-title">Abstract</h5>
                <p class="abstract-description">
                  {{ basic_details[0]?.abstract }}
                </p>
              </section>
              <section class="card date-court-card">
                <div class="date-court-content">
                  <div class="decision-date-card">
                    <div class="decision-day">{{ date[0] }}</div>
                    <time datetime="2016-10" class="decision-month-year">{{
                      date[1]
                    }}</time>
                    <span class="decision-date">Decision Date</span>
                  </div>
                  <div class="jurisdiction-court-card">
                    <div class="jurisdiction-card">
                      <p class="jurisdiction-title">Jurisdiction</p>
                      <h5 class="jurisdiction-location">
                        {{ basic_details[0]?.jurisdiction }}
                      </h5>
                    </div>
                    <div class="court-name-card">
                      <p class="court-name-title">Court Name</p>
                      <h5 class="court-name-description">
                        {{ basic_details[0]?.court }}
                      </h5>
                    </div>
                  </div>
                </div>
              </section>
            </article>

            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/86f8a62eda915b32cea76a2453ac2cc7d4d6e8910796cc2f1e7f7051a59c2468?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
              alt=""
              class="summary-divider"
            />

            <section class="summary">
              <header class="summary-header">
                <h3 class="summary-title">Summary</h3>
                <nav class="summary-tags">
                  <button class="copy-button">
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4042ce02169404fc8f24e2435141f1518abb04892a209162cf916b7ff7de4ac3?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                      alt="Copy icon"
                      class="img-button"
                    />
                    <span class="button-title">Copy</span>
                  </button>
                </nav>
              </header>
              <article class="case-summary">
                <header class="summary-sound">
                  <h5 class="brief-summary-title">Brief Summary</h5>
                  <button class="export-button">
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/be98e6e7c327af7d118fe81247fe84cb38848926818d2b97945aad22d1b8cb25?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                      alt="Export icon"
                      class="img-button"
                    />
                  </button>
                </header>
                <p class="brief-summary-description">
                  {{ brief_summary }}
                </p>
              </article>

              <section class="key-points-section">
                <h5 class="key-points-title">Key Points</h5>
                <ol class="key-points-list">
                  <li
                    class="key-point-item"
                    v-for="(item, index) in key_points"
                    :key="index"
                  >
                    <div
                      class="d-flex align-item-center justify-content-between gap-3"
                    >
                      <div class="text-container">
                        <span class="key-point-highlight">{{ item }}</span>
                      </div>
                      <span
                        class="material-symbols-rounded me-2 export-button"
                        @click="toggleSpeech(item, index)"
                      >
                        text_to_speech
                      </span>
                      <div
                        v-if="currentPlayingIndex === index"
                        style="margin-top: 5px; font-weight: bold"
                      >
                        {{ notification }}
                      </div>
                    </div>
                  </li>
                  <!-- Repeat similar structure for other list items -->
                </ol>
              </section>
            </section>
          </section>
        </div>
        <!-- second -->
        <div
          v-if="activeSection === 'completeCase'"
          class="main-container d-flex mt-4"
        >
          <article class="annotations-content-full-view">
            <header class="case-flow-result">{{ case_name }}</header>
            <iframe :src="case_file" width="100%" height="800px"></iframe>
            <footer class="case-copyright">
              NexLaw does not hold any copyright for the case content. The
              content within this material, including but not limited to text,
              images, graphics, logos, audio clips, and software, belongs to
              Comonlii and is protected by copyright laws. All rights are
              reserved by Comonlii (CommonLII is coordinated by AustLII, a joint
              facility of UTS and UNSW Faculties of Law, in cooperation with
              some of the members of the Free Access to Law Movement (FALM)).
              For Malaysian Cases, the copyright solely belongs to Malaysian
              Judiciary (e-court kehakiman).
            </footer>
          </article>
        </div>
        <!-- third -->
        <div
          v-if="activeSection === 'precedents'"
          class="main-container d-flex mt-4"
        >
          <section class="case-summary-content-full-view">
            <h5 class="case-flow-result">{{ case_name }}</h5>
            <ul class="precedent-list">
              <template v-if="link.length > 0">
                <div
                  v-for="(precedent, index) in link"
                  :key="index"
                  style="border-bottom: 1px solid var(--mid-grey)"
                >
                  <li
                    class="precedent-item"
                    @click="precedent.showInfo = !precedent.showInfo"
                  >
                    <span class="precedent-number">{{ index + 1 }}</span>
                    <article class="precedent-card">
                      <div class="precedent-content">
                        <p class="precedent-title">{{ precedent.name }}</p>
                        <p class="precedent-subtitle">Case Title</p>
                      </div>
                      <button
                        class="expand-button"
                        aria-label="Expand case details"
                      >
                        <img
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/500bc6b45c8c237ac58ee4a78c6d13ffab27bf757b25adc28aafeac3e0ca49b3?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                          alt=""
                          class="expand-icon"
                        />
                      </button>
                    </article>
                  </li>

                  <div
                    v-if="precedent.showInfo"
                    style="display: flex; justify-content: flex-end"
                  >
                    <section class="case-info-tit">
                      <header class="header">Case Info</header>
                      <article class="card">
                        <h5 class="topic">Topic</h5>
                        <p class="content">
                          {{ precedent.topic }}
                        </p>
                      </article>
                      <article class="card">
                        <h5 class="abstract">Abstract</h5>
                        <p class="content">
                          {{ precedent.abstract }}
                        </p>
                      </article>
                      <div class="card-2">
                        <div class="decision-date-card">
                          <div class="date-day">
                            {{ precedent.judgement_date.split(" ")[0] }}
                          </div>
                          <div class="date-month-year">
                            {{
                              precedent.judgement_date.split(" ")[1] +
                              " " +
                              precedent.judgement_date.split(" ")[2]
                            }}
                          </div>
                          <div class="decision-date">Decision Date</div>
                        </div>
                        <div class="jurisdiction">
                          <div class="jurisdiction-card">
                            <div class="jurisdiction-label">Jurisdiction</div>
                            <div class="jurisdiction-value">
                              {{ precedent.country }}
                            </div>
                          </div>
                          <div class="court-name-card">
                            <div class="court-name-label">Court Name</div>
                            <div class="court-name-value">
                              {{ precedent.court }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="empty-placeholder">
                  <span class="min-margin"></span>
                  <img src="@/assets/misc/empty.svg" alt="No Content" />
                  <p>
                    No Precedents Found <br />
                    We couldn’t generate any precedents from the uploaded file.
                    Try <br />
                    uploading a different document to summarize.
                  </p>
                </div></template
              >
            </ul>
          </section>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>
<script>
import CheckBox from "./../../components/input/CheckBox.vue";
import BreadCrumb from "../../components/misc/BreadCrumb.vue";
import advice from "@/store/advice";
import { ExportDataPDF, ExportDataDoc } from "@/store/utils";
import * as XLSX from "xlsx";
export default {
  name: "LegalMemoOpinion",
  components: {
    CheckBox,
    BreadCrumb,
  },
  data() {
    return {
      selectedExportOptions: [],
      dropdownItems: ["PDF", "Document"],
      activeSection: "caseSummary",
      precedents: [],
      basic_details: [],
      case_name: "",
      brief_summary: "",
      key_points: [],
      link: [],
      currentUtterance: null,
      currentPlayingIndex: null,
      notification: "",
      case_file: "",
    };
  },
  computed: {
    date() {
      if (this.basic_details[0]?.decision_date) {
        const parts = this.basic_details[0].decision_date.split(" ");
        const day = parts[0];
        const monthYear = parts.slice(1).join(" ");
        return [day, monthYear];
      }
      return ["", ""];
    },
  },
  methods: {
    exportToExcel() {
      // Parse the JSON string into an array of objects

      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      const formattedData = this.link.map((item) => {
        return {
          name: item.name || "-",
          link: item.link || "-",
          cite_no: item.cite_no || "-",
          judgement_date: item.judgement_date || "-",
          court: item.court || "-",
          country: item.country || "-",
          abstract: item.abstract || "-",
          topic: item.topic || "-",
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Cases");
      XLSX.writeFile(
        workbook,
        `Legal Advice : ${this.$route.params.id} - Document Summary ${this.$route.params.docId}.xlsx`
      );
    },
    toggleExport(e, item) {
      if (e) {
        if (!this.selectedExportOptions.includes(item))
          this.selectedExportOptions.push(item);
      } else if (!e) {
        const index = this.selectedExportOptions.indexOf(item);
        this.selectedExportOptions.splice(index, 1);
      }
    },
    exportData() {
      if (!this.selectedExportOptions.length) {
        this.$toast.warn("Kindly select a format to export");
        return;
      }

      const caseSummary = document.querySelector("#case-summary");
      if (!caseSummary) {
        this.$toast.error("The case summary section is missing!");
        return;
      }

      // Create a temporary container
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = caseSummary.innerHTML;

      // Remove unnecessary elements
      tempDiv.querySelectorAll("button").forEach((button) => button.remove());
      tempDiv.querySelectorAll("img").forEach((img) => img.remove());

      // Apply styles
      Object.assign(tempDiv.style, {
        width: "65%",
        maxWidth: "800px",
        margin: "20px auto",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "10px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        fontFamily: "Arial, sans-serif",
      });

      document.body.appendChild(tempDiv);

      // Export data
      try {
        if (this.selectedExportOptions.includes("PDF")) {
          ExportDataPDF(
            tempDiv.innerHTML,
            false,
            `Legal Advice : ${this.$route.params.id} - Document Summary ${this.$route.params.docId}`
          );
          this.$toast.success("Successfully downloaded PDF");
        }
        if (this.selectedExportOptions.includes("Document")) {
          ExportDataDoc(
            tempDiv.innerHTML,
            `Legal Advice : ${this.$route.params.id} - Document Summary ${this.$route.params.docId}`
          );
        }
      } catch (error) {
        this.$toast.error("An error occurred during export. Please try again.");
        console.error("Export error:", error);
      } finally {
        document.body.removeChild(tempDiv);
        this.selectedExportOptions = [];
      }
    },

    toggleSpeech(text, index) {
      if (this.currentPlayingIndex === index) {
        if (window.speechSynthesis.speaking && !window.speechSynthesis.paused) {
          window.speechSynthesis.pause();
          this.notification = "Paused";
        } else {
          window.speechSynthesis.resume();
          this.notification = "Playing";
        }
        return;
      }

      if (this.currentUtterance) {
        window.speechSynthesis.cancel();
        this.notification = "Switching to new speech...";
      }

      this.currentUtterance = new SpeechSynthesisUtterance(text);
      this.currentUtterance.onstart = () => {
        this.notification = "Playing";
        this.currentPlayingIndex = index;
      };

      this.currentUtterance.onend = () => {
        this.currentUtterance = null;
        this.currentPlayingIndex = null;
        this.notification = "";
      };

      window.speechSynthesis.speak(this.currentUtterance);
    },
    hideAllDropdown() {
      this.$root.$emit("bv::hide::tooltip");
    },
    goBack() {
      this.$router.go(-1);
    },
    export() {},
  },
  created() {
    advice
      .openDocument(this.$route.params.id, this.$route.params.docId)
      .then((res) => {
        const DATA = res.data.data;
        this.case_name = DATA.case_name;
        this.case_file = DATA.case_file;
        this.basic_details = JSON.parse(
          DATA.values.find((item) => item.key === "basic_details").value
        );

        this.brief_summary = JSON.parse(
          DATA.values.find((item) => item.key === "brief_summary").value
        );

        this.key_points = JSON.parse(
          DATA.values.find((item) => item.key === "key_points").value
        );

        this.link = JSON.parse(
          DATA.values.find((item) => item.key === "link").value
        ).map((item) => {
          return {
            ...item,
            showInfo: false,
          };
        });
      });
  },
  mounted() {
    window.speechSynthesis.onend = () => {
      this.currentUtterance = null; // Reset the current utterance
      this.isPlaying = false; // Reset the playing state
      this.notification = ""; // Clear notification
    };
  },
};
</script>

<style scoped>
.empty-placeholder {
  min-height: 40%;
  width: 500px;

  .min-margin {
    display: inline-block;
    height: 50px;
  }

  img {
    margin-top: auto;
    margin-bottom: 24px;
  }

  p {
    text-align: center;
    margin-bottom: 32px;
  }
}

.min-margin {
  display: inline-block;
  height: 50px;
}

.row {
  padding-left: 0px !important;
  --bs-gutter-x: 0px !important;
}
.left {
  color: var(--primary);
  font-weight: 500;
  cursor: pointer;
}
.right {
  background: var(--primary);
  color: #fff;
  width: 114px;
  height: 32px;
  padding: 4px 16px;
  gap: 8px;
  border-radius: 6px;
  cursor: pointer;
}
.main-container {
  display: flex;
  justify-content: space-between;
  max-width: 880px;
  width: 100%;
  margin: auto;
}
</style>

<style scoped>
.case-summary-content-full-view {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px 0 rgba(227, 229, 235, 0.6);
  padding: 0 0 32px 32px;
}
@media (max-width: 991px) {
  .case-summary-content-full-view {
    padding-left: 20px;
  }
}

.case-summary-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  z-index: 10;
  gap: 20px;
  flex-wrap: wrap;
  font-family: Poppins, sans-serif;
  color: var(--Neutral-Black, #383a3e);
  border-bottom: 1px solid var(--mid-grey);
}

.case-flow-result {
  padding: 20px 0;
  font-size: 20px;
  font-weight: 600;
  margin: auto 0;
}

.dropdown-modal {
  display: flex;
  flex-direction: column;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px 0 rgba(227, 229, 235, 0.6);
  border: 1px solid #f2f3f3;
  border-radius: 8px;
}

.dropdown-link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
}

.checkbox,
.checkbox-2 {
  width: 24px;
  height: 24px;
  border: 1px solid var(--Neutral-Black, #383a3e);
  border-radius: 4px;
}

.dropdown-link-2 {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  color: var(--Neutral-White, #fff);
}

.button {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
  padding: 2px 8px;
}

.img-button {
  width: 18px;
}

.button-title,
.dropdown-text {
  margin: auto 0;
  cursor: pointer;
}
.icon-decorative {
  width: 20px;
  margin: -19px 0 0 30px;
}

.case-info {
  display: flex;
  flex-direction: column;
  margin-top: -12px;
  max-width: 816px;
  width: 100%;
}

.header {
  font-size: 20px;
  font-weight: 600;
  color: var(--Neutral-Black, #383a3e);
}

.card {
  border: none !important;
  display: flex;
  margin-top: 24px;
}
.topic-card .topic-title,
.abstract-card .abstract-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--Neutral-Black, #383a3e);
  border: none !important;
}

.topic-card .topic-description,
.abstract-card .abstract-description {
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-Dark-Grey, #86888d);
  margin-top: 4px;
}
.date-court-card {
  border: none !important;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.date-court-content {
  display: flex;
  gap: 20px;
}
.decision-date-card,
.jurisdiction-card,
.court-name-card {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  padding: 18px 38px;
  border-radius: 8px;
}

.decision-day {
  font-size: 25px;
  font-weight: 600;
}

.decision-month-year {
  font-size: 16px;
}
.decision-date {
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-Dark-Grey, #86888d);
  margin-top: 8px;
}

.jurisdiction-title,
.court-name-title {
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-Dark-Grey, #86888d);
}
.jurisdiction-location,
.court-name-description {
  font-size: 16px;
}

.summary-divider {
  width: 100%;
  margin-top: 24px;
}

.summary {
  display: flex;
  flex-direction: column;
  margin-top: 23px;
  max-width: 816px;
}

.summary-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}
.summary-sound {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}
.summary-title {
  margin: auto 0;
  color: var(--Neutral-Black, #383a3e);
}

.summary-tags {
  display: flex;
  gap: 8px;
  font-size: 14px;
}

.copy-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  background: var(--Neutral-White, #fff);
  border-radius: 4px;
}

.case-summary {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.brief-summary-title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.brief-summary-description {
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-Dark-Grey, #86888d);
  margin-top: 12px;
}

.key-points-section {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.key-points-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--Neutral-Black, #383a3e);
}
.key-points-list {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.key-point-item {
  font-size: 14px;
  font-weight: 400;
  color: var(--Neutral-Dark-Grey, #86888d);
  margin-bottom: 12px;
}
.key-point-highlight {
  color: var(--Neutral-Black, #383a3e);
}

.expand-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px;
  border-radius: 4px;
}

.icon-expand {
  width: 24px;
}
#export_box {
  width: 239px !important;
  height: 148px !important;
  left: -85px !important;
}
.DROPDOWN-MENU ::v-deep .tooltip-inner .dropdown-option span {
  margin-right: 0px !important;
}
.left-list {
  position: sticky;
  top: 0;
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: var(--dark-grey) !important;
  cursor: pointer;
}
.left-list li.active {
  color: #1890ff;
}
</style>

<style scoped>
.annotations-content-full-view {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px 0 rgba(227, 229, 235, 0.6);
  display: flex;
  max-width: 880px;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  color: var(--Neutral-Black, #383a3e);
  justify-content: flex-start;
  padding: 32px;
}

.case-flow-result {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
}

.case-image {
  aspect-ratio: 0.98;
  object-fit: contain;
  object-position: center;
  width: 100%;
  margin-top: 32px;
}

.case-copyright {
  align-self: stretch;
  flex: 1;
  margin-top: 32px;
  min-height: 96px;
  width: 100%;
  gap: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 991px) {
  .annotations-content-full-view {
    padding: 0 20px;
  }

  .case-image,
  .case-copyright {
    max-width: 100%;
  }
}
</style>

<style scoped>
.case-summary-content-full-view {
  border-radius: 8px;
  background-color: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px 0 rgba(227, 229, 235, 0.6);
  max-width: 880px;
  width: 100%;
  padding: 32px;
}

.case-flow-result {
  color: var(--Neutral-Black, #383a3e);
}

.precedent-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.precedent-item {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.precedent-number {
  border-radius: 50%;
  background-color: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 500 14px/1 Poppins, sans-serif;
}

.precedent-card {
  border-radius: 8px;
  background-color: var(--Neutral-Light-Grey, #f2f3f3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 770px;
  padding: 4px 8px;
}

.precedent-content {
  flex: 1;
}

.precedent-title {
  color: var(--Neutral-Black, #383a3e);
  font: 500 16px/28px Poppins, sans-serif;
  margin: 0;
}

.precedent-subtitle {
  color: var(--Neutral-Dark-Grey, #86888d);
  font: 400 14px/24px Poppins, sans-serif;
  margin: 4px 0 0;
}

.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
}

.expand-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 991px) {
  .case-summary-content-full-view {
    padding: 20px;
  }

  .precedent-card {
    width: 100%;
  }
}
</style>

<style scoped>
.case-info-tit {
  display: flex;
  flex-direction: column;
  width: 770px;
  max-width: 770px;
  padding: 8px 16px;
  background: #fff;
  color: #383a3e;
  border: 1px solid #1890ff;
  border-radius: 8px;
  margin-bottom: 20px;
}
.header {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  width: 100%;
}

.card,
.card-2 {
  margin-top: 16px;
  width: 100%;
  display: flex;
  overflow: hidden;
}
.topic,
.abstract {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.content {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #86888d;
  margin-top: 4px;
}
.card-2 {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  font-weight: 500;
  justify-content: flex-start;
}
.decision-date-card,
.jurisdiction-card,
.court-name-card {
  display: flex;
  flex-direction: column;
  padding: 18px 38px;
  background: #fafbfc;
  border-radius: 8px;
  color: #383a3e;
}

.date-day {
  font-size: 32px;
  font-weight: 600;
}
.date-month-year {
  font-size: 16px;
  line-height: 28px;
}

.decision-date,
.jurisdiction-label,
.court-name-label {
  margin-top: 8px;
  font-size: 14px;
  color: #86888d;
  line-height: 1;
}
.jurisdiction,
.court-name-card {
  margin-top: 16px;
  padding: 8px 8px 8px 8px;
  min-width: 240px;
  flex: 1;
  flex-basis: 76px;
}

.jurisdiction-value,
.court-name-value {
  font-size: 16px;
  color: #383a3e;
}

.DROPDOWN-MENU::v-deep .tooltip-inner {
  max-width: 230px;
  width: 230px;
  margin-left: -30px;
}
</style>
